// Add your JS customizations here
jQuery(document).ready(function($) {

	if($(document).scrollTop() >= 20) {
		$('#main-nav').addClass('scroll-active');
		$('#main-nav').removeClass('other-active');
	} else {
		if($('body').hasClass('single-condition') || $('body').hasClass('blog') || $('body').hasClass('search') || $('body').hasClass('archive') || $('body').hasClass('single-post') || $('body').hasClass('page-template-page-what') || $('body').hasClass('page-template-page-how') || $('body').hasClass('page-template-page-case') || $('body').hasClass('page-template-page-journey') || $('body').hasClass('page-template-page-gallery') || $('body').hasClass('page-template-page-policy')) {
			$('#main-nav').addClass('other-active');
		} else {
			$('#main-nav').removeClass('scroll-active');
		}
	}

	$(document).on( 'scroll', function(){

		if($(document).scrollTop() >= 20) {
			$('#main-nav').addClass('scroll-active');
			$('#main-nav').removeClass('other-active');
		} else {
			if($('body').hasClass('single-condition') || $('body').hasClass('blog') || $('body').hasClass('search') || $('body').hasClass('archive') || $('body').hasClass('single-post') || $('body').hasClass('page-template-page-what') || $('body').hasClass('page-template-page-how') || $('body').hasClass('page-template-page-case') || $('body').hasClass('page-template-page-journey') || $('body').hasClass('page-template-page-gallery') || $('body').hasClass('page-template-page-policy')) {
				$('#main-nav').addClass('other-active');
			} else {
				$('#main-nav').removeClass('scroll-active');
			}
		}

	});

	if($('.featured-treatments .featured-treatments-wrapper.mobile').length > 0) {
		$('.featured-treatments .featured-treatments-wrapper.mobile').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: false,
			dots: false,
			autoplay: false,
			variableWidth: true
		});
	}

	let treatmentItemWidth = $('.featured-treatments .featured-treatments-wrapper.mobile').outerWidth();

	$('.featured-treatments .featured-treatments-wrapper.mobile .featured-treatments-item').css('with', treatmentItemWidth - 60);
	$('.featured-treatments .featured-treatments-wrapper.mobile .featured-treatments-item').css('max-width', treatmentItemWidth - 60);

	$(window).on('resize', function() {
		let treatmentItemWidth = $('featured-treatments .featured-treatments-wrapper.mobile').outerWidth();

		$('.featured-treatments .featured-treatments-wrapper.mobile .featured-treatments-item').css('with', treatmentItemWidth - 60);
		$('.featured-treatments .featured-treatments-wrapper.mobile .featured-treatments-item').css('max-width', treatmentItemWidth - 60);
	});

	setInterval(function () {
		let treatmentItemWidth = $('.featured-treatments .featured-treatments-wrapper.mobile').outerWidth();

		$('.featured-treatments .featured-treatments-wrapper.mobile .featured-treatments-item').css('with', treatmentItemWidth - 60);
		$('.featured-treatments .featured-treatments-wrapper.mobile .featured-treatments-item').css('max-width', treatmentItemWidth - 60);
	}, 400);

	function treatmentSpotlightAnimation() {
		$('.featured-treatments').each(function() {
			var $container = $(this);
			var $items = $container.find('.featured-treatments-wrapper.desktop .featured-treatments-item');
			var $prevArrow = $container.find('.arrow-prev');
			var $nextArrow = $container.find('.arrow-next');

			function applyWidths($activeItem) {
				var inactiveWidth = 7.5; // Fixed percentage width for inactive items
				var activeWidth = 100 - (($items.length - 1) * inactiveWidth); // Calculate width percentage for the active item

				$items.each(function() {
					var $item = $(this);
					if ($item[0] !== $activeItem[0]) {
						// Set the width of the inactive items
						$item.css('width', 'calc(' + inactiveWidth + '% - 2rem)').removeClass('active');
						$item.css('min-width', 'calc(' + inactiveWidth + '% - 2rem)').removeClass('active');
					} else {
						// Set the width of the active item
						$item.css('width', 'calc(' + activeWidth + '% - 2rem)').addClass('active');
						$item.css('min-width', 'calc(' + activeWidth + '% - 2rem)').addClass('active');
					}
				});
			}

			function applyWidths($activeItem) {
				var inactiveWidth = 7.5; // Fixed percentage width for inactive items
				var activeWidth = 100 - (($items.length - 1) * inactiveWidth); // Calculate width percentage for the active item

				$items.each(function() {
					var $item = $(this);
					if ($item[0] !== $activeItem[0]) {
						// Set the width of the inactive items
						$item.css('width', 'calc(' + inactiveWidth + '% - 2rem)').removeClass('active');
						$item.css('min-width', 'calc(' + inactiveWidth + '% - 2rem)').removeClass('active');
					} else {
						// Set the width of the active item
						$item.css('width', 'calc(' + activeWidth + '% - 2rem)').addClass('active');
						$item.css('min-width', 'calc(' + activeWidth + '% - 2rem)').addClass('active');
					}
				});
			}

			function setActiveItem($newActiveItem) {
				// Remove the active class from all items
				$items.removeClass('active');

				// Add the active class to the new active item
				$newActiveItem.addClass('active');

				// Apply the new widths
				applyWidths($newActiveItem);
			}

			function getItemByIndex(index) {
				if (index >= 0 && index < $items.length) {
					return $items.eq(index);
				}
				return null;
			}

			function navigateToItem(delta) {
				var $currentActive = $items.filter('.active');
				var currentIndex = $items.index($currentActive);
				var newIndex = (currentIndex + delta + $items.length) % $items.length; // Ensure wrapping around
				var $newActiveItem = getItemByIndex(newIndex);

				if ($newActiveItem) {
					setActiveItem($newActiveItem);
				}
			}

			// Apply widths on load
			var $activeItem = $items.filter('.active');
			if ($activeItem.length === 0) {
				// Default to the first item if no active item is found
				$activeItem = $items.first();
			}
			setActiveItem($activeItem);

			// Add click event listeners for prev/next arrows within this container
			$prevArrow.on('click', function(e) {
				e.preventDefault();
				e.stopPropagation(); // Prevent this click from propagating to the item
				navigateToItem(-1); // Move to the previous item
			});

			$nextArrow.on('click', function(e) {
				e.preventDefault();
				e.stopPropagation(); // Prevent this click from propagating to the item
				navigateToItem(1); // Move to the next item
			});

			// Add click event listeners for each item within this container
			$items.on('click', function(e) {
				var $clickedItem = $(this);

				// If the clicked element is an <a> or inside an <a>, let the link work
				if ($(e.target).is('a') || $(e.target).closest('a').length) {
					return; // Do not prevent the default behavior for anchor tags
				}

				e.preventDefault(); // Prevent default behavior for other clicks
				setActiveItem($clickedItem); // Set the clicked item as active
			});
		});
	}

	treatmentSpotlightAnimation();

	if($('.featured-conditions .featured-conditions-wrapper').length > 0) {
		$('.featured-conditions .featured-conditions-wrapper').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: false,
			variableWidth: false,
			centerMode: false,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						arrows: true,
						dots: false,
						autoplay: false,
						centerMode: false,
						variableWidth: false,
					}
				}
			]
		});
	}

	if($('.treatment-hero .at-a-glance .items').length > 0) {
		$('.treatment-hero .at-a-glance .items').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: false,
			variableWidth: false,
		});
	}

	if($('.before-after .items').length > 0) {
		$('.before-after .items').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: false,
			variableWidth: false,
		});
	}

	if($('.videos-block .items').length > 0) {
		$('.videos-block .items').slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: false,
			variableWidth: false,
		});
	}

	if($('.testimonials-block .testimonials').length > 0) {
		$('.testimonials-block .testimonials').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: false,
			variableWidth: false,
		});
	}

	if($('.background-image-block .case-studies').length > 0) {
		$('.background-image-block .case-studies').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: false,
			variableWidth: false,
		});
	}
	if($('.case-studies .images.mobile').length > 0) {
		$('.case-studies .images.mobile').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: false,
			centerMode: false,
			variableWidth: true,
			responsive: [
				{
					breakpoint: 9999,
					settings: "unslick"
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
					}
				}
			]
		});
	}

	if($('.gallery-block .content .category .category-items').length > 0) {
		$('.gallery-block .content .category .category-items').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: false,
			variableWidth: false,
			centerMode: true,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						arrows: true,
						dots: false,
						autoplay: false,
						centerMode: false,
						variableWidth: false,
					}
				}
			]
		});
	}

	$('.slick-next').html('<svg version="1.1" class="slick-next-arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 42.1 27.9" style="enable-background:new 0 0 42.1 27.9;" xml:space="preserve"> <style type="text/css">.slick-next-arrow-1{fill:#FFFFFF;}</style><path class="slick-next-arrow-1" d="M0,14.5h40.4L24.8,27.1l0.6,0.8l16.7-13.5c0-0.3,0-0.5,0-0.8L25.4,0l-0.6,0.8l15.6,12.8H0L0,14.5z"/></svg>');
	$('.slick-prev').html('<svg version="1.1" class="slick-prev-arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 42.1 27.9" style="enable-background:new 0 0 42.1 27.9;" xml:space="preserve"> <style type="text/css">.slick-prev-arrow-1{fill:#FFFFFF;}</style><path class="slick-prev-arrow-1" d="M42.1,14.5H1.7l15.6,12.6l-0.6,0.8L0,14.4c0-0.3,0-0.5,0-0.8L16.7,0l0.6,0.8L1.7,13.5h40.4V14.5z"/></svg>');

	$(window).resize(function () {
		if($('.case-studies .images.mobile').length > 0) {
			$('.case-studies .images.mobile').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				arrows: true,
				dots: false,
				autoplay: false,
				centerMode: false,
				variableWidth: true,
				responsive: [
					{
						breakpoint: 9999,
						settings: "unslick"
					},
					{
						breakpoint: 991,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							infinite: true,
						}
					}
				]
			});
		}

		$('.slick-next').html('<svg version="1.1" class="slick-next-arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 42.1 27.9" style="enable-background:new 0 0 42.1 27.9;" xml:space="preserve"> <style type="text/css">.slick-next-arrow-1{fill:#FFFFFF;}</style><path class="slick-next-arrow-1" d="M0,14.5h40.4L24.8,27.1l0.6,0.8l16.7-13.5c0-0.3,0-0.5,0-0.8L25.4,0l-0.6,0.8l15.6,12.8H0L0,14.5z"/></svg>');
		$('.slick-prev').html('<svg version="1.1" class="slick-prev-arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 42.1 27.9" style="enable-background:new 0 0 42.1 27.9;" xml:space="preserve"> <style type="text/css">.slick-prev-arrow-1{fill:#FFFFFF;}</style><path class="slick-prev-arrow-1" d="M42.1,14.5H1.7l15.6,12.6l-0.6,0.8L0,14.4c0-0.3,0-0.5,0-0.8L16.7,0l0.6,0.8L1.7,13.5h40.4V14.5z"/></svg>');
	});

	setInterval(function () {
		$('.slick-next').html('<svg version="1.1" class="slick-next-arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 42.1 27.9" style="enable-background:new 0 0 42.1 27.9;" xml:space="preserve"> <style type="text/css">.slick-next-arrow-1{fill:#FFFFFF;}</style><path class="slick-next-arrow-1" d="M0,14.5h40.4L24.8,27.1l0.6,0.8l16.7-13.5c0-0.3,0-0.5,0-0.8L25.4,0l-0.6,0.8l15.6,12.8H0L0,14.5z"/></svg>');
		$('.slick-prev').html('<svg version="1.1" class="slick-prev-arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 42.1 27.9" style="enable-background:new 0 0 42.1 27.9;" xml:space="preserve"> <style type="text/css">.slick-prev-arrow-1{fill:#FFFFFF;}</style><path class="slick-prev-arrow-1" d="M42.1,14.5H1.7l15.6,12.6l-0.6,0.8L0,14.4c0-0.3,0-0.5,0-0.8L16.7,0l0.6,0.8L1.7,13.5h40.4V14.5z"/></svg>');
	}, 400);

	function changeImageOnMobile(container) {
		$(container).each(function() {
			let imgForMobile = $(this).data('mobile');
			let imgForDesktop = $(this).data('desktop');
			let background = $(this); // Store a reference to the background element

			// Function to update background image based on window width
			if(imgForMobile) {
				function updateBackgroundImage() {
					if ($(window).width() < 991) {
						background.css('background-image', 'url(' + imgForMobile + ')');
					} else {
						background.css('background-image', 'url(' + imgForDesktop + ')');
					}
				}

				// Call the function initially
				updateBackgroundImage();

				// Call the function on window resize
				$(window).on('resize', updateBackgroundImage);
			}
		});
	}

	changeImageOnMobile('.hero-home .image');
	changeImageOnMobile('.founder-block .background');
	changeImageOnMobile('.background-image-block .background');
	changeImageOnMobile('.small-background-block .content');

	function handleMenuClick(triggerClass) {
		// Extract the target class by removing the '-trigger' part from the triggerClass
		var targetClass = triggerClass.replace('-trigger', '').replace('.', '');

		$(triggerClass).on('click', function(e) {
			e.preventDefault(); // Prevent default link behavior

			// Add the menu-open class to #main-nav
			$('#main-nav').addClass('menu-open');

			// Remove the active class from all menu containers
			$('#main-nav .inner-menu-container .menu-container').removeClass('active');

			if ($(window).width() < 991) {
				$('#main-nav .main-nav-container .bottom').addClass('active-mobile');
			}

			// Add the active class to the targeted menu container
			$('#main-nav .inner-menu-container .menu-container.' + targetClass).addClass('active');
		});
	}

// Automatically handle clicks for any trigger ending with '-trigger'
	$('#main-nav .main-nav-container [class$="-trigger"]').each(function() {
		var triggerClass = '.' + $(this).attr('class').split(' ').find(function(cls) {
			return cls.endsWith('-trigger');
		});
		handleMenuClick(triggerClass);
	});

// Handle the close button click
	$('#main-nav .inner-menu-container .close').on('click', function(e) {
		e.preventDefault();
		// Remove the menu-open class from #main-nav
		$('#main-nav').removeClass('menu-open');

		// Remove the active class from all menu containers
		$('#main-nav .inner-menu-container .menu-container').removeClass('active');
		$('#main-nav .main-nav-container .bottom').removeClass('active');
	});

	$('#main-nav .menu-button').on('click', function(e) {
		e.preventDefault();
		// Toggle the 'menu-open' class on #main-nav
		$('#main-nav').toggleClass('menu-open');

		// Find the <i> element inside the .menu-trigger
		var $icon = $(this).find('i');

		// Toggle the icon class between fa-bars and fa-xmark
		if ($icon.hasClass('fa-bars')) {
			$icon.removeClass('fa-bars fa-sharp fa-light').addClass('fa-sharp fa-light fa-xmark');
		} else {
			$icon.removeClass('fa-sharp fa-light fa-xmark').addClass('fa-bars fa-sharp fa-light');
		}
	});

	$('#main-nav .inner-menu-container .mobile-close').on('click', function(e) {
		e.preventDefault();

		// Remove the active class from all menu containers
		$('#main-nav .inner-menu-container .menu-container').removeClass('active');
		$('#main-nav .main-nav-container .bottom').removeClass('active-mobile');
	});

	$('.background-image-block .menu-trigger').on('click', function () {
		var triggerClass = '.' + $(this).attr('class').split(' ').find(function(cls) {
			return cls.endsWith('-trigger');
		});

		handleMenuClick(triggerClass);
	})

	$('.treatment-hero a').on('click', function(event) {
		event.preventDefault(); // Prevent the default anchor click behavior

		// Get the target class from the data-target attribute
		var targetClass = $(this).data('target');

		// Find the first element with the target class and scroll to it
		var $targetElement = $('.' + targetClass);

		if ($targetElement.length) {
			$('html, body').animate({
				scrollTop: $targetElement.offset().top
			}, 400); // Adjust the duration as needed
		}
	});

	$('.hero-thank .scroll-button').on('click', function(event) {
		event.preventDefault(); // Prevent the default anchor click behavior

		// Get the target class from the data-target attribute
		var targetClass = $(this).data('target');

		// Find the first element with the target class and scroll to it
		var $targetElement = $('.' + targetClass);

		if ($targetElement.length) {
			$('html, body').animate({
				scrollTop: $targetElement.offset().top
			}, 400); // Adjust the duration as needed
		}
	});

	var $modal = $('#videoModal');
	var $videoContainer = $('#videoContainer');
	var $closeBtn = $('.modal .close');

	$('.videos-block .item').on('click', function() {
		var videoSource = $(this).data('source');
		var videoId = $(this).data('id');

		if (videoSource === 'youtube') {
			$videoContainer.html('<lite-youtube videoid="' + videoId + '" params="rel=0"></lite-youtube>');
		} else if (videoSource === 'vimeo') {
			$videoContainer.html('<iframe src="https://player.vimeo.com/video/' + videoId + '" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>');
		}

		$modal.show(); // Show the modal
	});

	$('.gallery-block .item').on('click', function() {
		var videoSource = $(this).data('source');
		var videoId = $(this).data('id');

		if (videoSource === 'youtube') {
			$videoContainer.html('<lite-youtube videoid="' + videoId + '" params="rel=0"></lite-youtube>');
		} else if (videoSource === 'vimeo') {
			$videoContainer.html('<iframe src="https://player.vimeo.com/video/' + videoId + '" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>');
		}

		$modal.show(); // Show the modal
	});

	$closeBtn.on('click', function() {
		$modal.hide(); // Hide the modal
		$videoContainer.empty(); // Remove the video
	});

	// Hide the modal if the user clicks outside of the modal content
	$(window).on('click', function(event) {
		if ($(event.target).is($modal)) {
			$modal.hide();
			$videoContainer.empty();
		}
	});

	$('.dropdown-toggle').on('click', function() {
		$('.mobile-dropdown').toggleClass('show');
	});

	// Optionally close the dropdown when clicking outside of it
	$(window).on('click', function(event) {
		if (!$(event.target).closest('.mobile-dropdown').length) {
			$('.mobile-dropdown').removeClass('show');
		}
	});

	$('.contra-indications .dropdown-toggle').on('click', function() {
		$('.contra-indications').toggleClass('show');
	});

	$(".widget-area aside.widget_categories select").select2();
	$(".widget-area aside.widget_archive select").select2();

	$('.select2-selection__arrow').html('<i class="fa-sharp fa-regular fa-chevron-down"></i>');

	$(".select2 #select2-archives-dropdown-2-container").attr('title', 'Archives').html('Archives');

	$(document).ready(function() {
		// Toggle the visibility of the dropdown content when the "Filter" button is clicked
		$('.filter-toggle').on('click', function() {
			$(this).siblings('.dropdown-content').slideToggle(); // Slide the dropdown content up/down
			$(this).toggleClass('active');
		});
	});

	$('.blog-posts-content .post-content .content-wrapper *:has(iframe)').each(function () {
		// Add styles to the wrapping p
		$(this).css({
			'position': 'relative',
			'padding-top': '56.25%',
			'width': '100%',
			'margin': '4rem 0 3rem',
			'align-self': 'center',
			'overflow': 'hidden' // Optional: Hide overflowing content
		});

		if ($(window).width() < 991) {
			$(this).css({
				'position': 'relative',
				'padding-top': '56.25%',
				'width': '100%',
				'margin': '1rem 0 2rem',
				'align-self': 'center',
				'overflow': 'hidden' // Optional: Hide overflowing content
			});		} else {
			$(this).css({
				'position': 'relative',
				'padding-top': '56.25%',
				'width': '100%',
				'margin': '4rem 0 3rem',
				'align-self': 'center',
				'overflow': 'hidden' // Optional: Hide overflowing content
			});		}
	});

	$('.policy-content .content-wrapper *:has(iframe)').each(function () {
		// Add styles to the wrapping p
		$(this).css({
			'position': 'relative',
			'padding-top': '56.25%',
			'width': '100%',
			'margin': '4rem 0 3rem',
			'align-self': 'center',
			'overflow': 'hidden' // Optional: Hide overflowing content
		});

		if ($(window).width() < 991) {
			$(this).css({
				'position': 'relative',
				'padding-top': '56.25%',
				'width': '100%',
				'margin': '1rem 0 2rem',
				'align-self': 'center',
				'overflow': 'hidden' // Optional: Hide overflowing content
			});		} else {
			$(this).css({
				'position': 'relative',
				'padding-top': '56.25%',
				'width': '100%',
				'margin': '4rem 0 3rem',
				'align-self': 'center',
				'overflow': 'hidden' // Optional: Hide overflowing content
			});		}
	});

	var tableWrappers = $('.case-studies .table-wrapper');

	tableWrappers.each(function() {
		// Select the .font-3 element inside the current .table-wrapper
		var font3 = $(this).find('.top');

		// Add click event listener to the .font-3 element
		font3.click(function(event) {
			event.preventDefault(); // Prevent default action (if any)

			// Current .table-wrapper and its .content
			var currentWrapper = $(this).closest('.table-wrapper');
			var currentContent = currentWrapper.find('.content');
			var currentIcon = $(this).find('i');

			// If the current .table-wrapper is already active
			var isActive = currentContent.hasClass('active');

			// Remove 'active' class from all .content and reset icons to .fa-plus
			tableWrappers.find('.content').removeClass('active');
			tableWrappers.find('.font-2 i').removeClass('fa-minus').addClass('fa-plus');

			// If the current .table-wrapper was not active, activate it
			if (!isActive) {
				currentContent.addClass('active');
				currentIcon.removeClass('fa-plus').addClass('fa-minus');
			}
		});
	});

	$('.gallery-block .conditions.content').addClass('active');
	$('.gallery-block .treatments.content').removeClass('active');

	// Handle click on the Conditions button
	$('.gallery-block .top .conditions').click(function(e) {
		e.preventDefault(); // Prevent the default anchor behavior
		$('.gallery-block .treatments.content').removeClass('active'); // Hide the treatments content
		$('.gallery-block .conditions.content').addClass('active'); // Show the conditions content
		$(this).addClass('active');
		$('.gallery-block .top .treatments').removeClass('active');
	});

	// Handle click on the Treatments button
	$('.gallery-block .top .treatments').click(function(e) {
		e.preventDefault(); // Prevent the default anchor behavior
		$('.gallery-block .conditions.content').removeClass('active'); // Hide the conditions content
		$('.gallery-block .treatments.content').addClass('active');// Show the treatments content
		$(this).addClass('active');
		$('.gallery-block .top .conditions').removeClass('active');
	});

	$('#main-nav .close-search').on('click', function (e) {
		e.preventDefault();

		$('#main-nav .search-form').toggleClass('active');
		$('#main-nav .nav-search-form').toggleClass('active');
	});

	$('#main-nav .search-button').on('click', function (e) {
		e.preventDefault();

		$('#main-nav .search-form').toggleClass('active');
		$('#main-nav .nav-search-form').toggleClass('active');
	});

});
